import { ReactNode } from "react";

import {
  CONDITION_OPERATOR,
  DEFAULT_DASHBOARD,
  DOCUMENT_SCOPE,
  FOLDER_TYPE,
  LIST_SCOPE,
  META_EXPRESSION_SCOPE,
  OPERATION_OPERATOR,
  PACK_ID,
  PLAN_ID,
} from "fieldpro-tools";

import { DASHBOARD_STATUS_TYPE } from "model/entities/Dashboard";

export type THoverToolTipsLang = {
  important: string;
  locked: string;
  hidden: string;
  mandatory: string;
  unique: string;
  hasQuery: string;
};

export enum ENTITIES {
  CLIENT = "clients",
  DASHBOARD = "dashboards",
  LIST = "lists",
  TEAM = "teams",
  USER = "users",
  WORKFLOW = "workflows",
  PICTURE = "pictures",
  DOCUMENT = "documents",
  JOB = "jobs",
  TRIGGER = "triggers",
  ORDER = "orders",
  WEBHOOK = "webhooks",
  ENVIRONMENT = "environment",
  CALENDAR = "calendar",
}

export enum SUB_CATEGORIES {
  CLIENT = "clients",
  CLIENT_SUBSCRIPTION = "subscriptions",
  CLIENT_BILLING = "billing",
  CLIENT_PLAN = "plans",
  CLIENT_PACK = "packs",
  DASHBOARD = "dashboards",
  LIST = "lists",
  ITEM = "items",
  TERRITORY = "territories",
  TEAM = "teams",
  GROUP = "groups",
  MOBILE_USER = "mobileUsers",
  WEB_USER = "webUsers",
  WORKFLOW = "workflows",
  ACTIVITY = "activities",
  ACTIVITY_REPORT = "activityReports",
  JOB_HISTORY = "jobHistory",
  PICTURE = "pictures",
  DOCUMENT = "documents",
  TRANSFORMATION = "transformations",
  TABLEOPTIMIZATION = "tableOptimizations",
  NOTIFICATION = "notifications",
  HTTP_REQUEST = "http_requests",
  INTEGRATION = "integrations",
  STREAM = "streams",
  SCRIPT = "scripts",
  JOB = "jobs",
  TRIGGER = "triggers",
  WEBHOOK = "webhooks",
  META_EXPRESSION = "metaExpressions",
  WORKFLOW_REPORT = "workflowReports",
  DATABASE_SCHEMA = "databaseSchema",
  FOLDER = "folders",
  CALENDAR_EVENT = "calendarEvent",
}

// all the custom actions where cannot be generalise (all the "standard" actions are in lang.actions)
export type TCustomDirectActionLabel = {
  bulkLicense: string;
  bulkUnlicense: string;
  bulkArchive: string;
  bulkUnassign: string;
  bulkUnassignMobileUsers: string;
  bulkEdit: string;
  bulkRestore: string;
  bulkDelete: string;
  bulkAssignToTeams: string;
  bulkAssignToMobileUsers: string;
  bulkDeleteMobileUser: string;
  bulkDownloadQRCodes: string;
  downloadKmlListFile: string;
  downloadGeojsonFile: string;
  select: string;
  detail: string;
  archive: string;
  download?: string;
  export?: string;
  fetchRawData: string;
  restoreVersion: string;
  findDuplicates: string;
  openDetailPanel: string;
  manageHierarchy: string;
  openConfigurationPanel: string;

  bulkArchiveWithCSV: string;
  bulkRestoreWithCSV: string;
  bulkEditWithCSV: string;
  bulkDeleteWithCSV: string;

  bulkAssignWebUsersToTeams: string;
  bulkAssignWorkflowsToTeams: string;
  bulkAssignMobileUsersToTeams: string;
  bulkUnAssignWebUsersFromTeams: string;
  bulkUnAssignMobileUsersFromTeams: string;
};

export enum ERROR_LIST {
  EMPTY = "empty",
  MANDATORY_FIELD = "mandatoryField",
  TOO_SHORT = "tooShort",
  NOT_POSSIBLE_TO_MODIFY_BECAUSE_TEMPLATE = "notPossibleToModifyBecauseTemplate",
  SIMILAR_TAG_WARNING = "similarTagWarning",
  TOO_LONG = "tooLong",
  START_WITH_LETTER = "startWithLetter",
  ALPHANUMERIC = "alphanumeric",
  ALPHANUMERIC_META_EXPRESSION = "alphanumericMetaExpression",
  ALREADY_IN_USE = "alreadyInUse",
  WRONG_FORMAT = "wrongFormat",
  IMPOSSIBLE_TO_FIND = "impossibleToFind",
  MAXIMUM = "maximum",
  MINIMUM = "minimum",
  OPTION_FORBIDDEN_CHARACTERS = "optionLabelForbiddenCharacters",
  UNLINKED_STEPS = "unlinkedSteps",
  NOT_SECURED_ENOUGH = "notSecuredEnough",
  MORE_THAN_ONE_START_WORFKLOW = "moreThanOneStartWorkflow",
  IN_DEPRECATED = "inDeprecated",
  EMAIL_CONTAIN_UPPERCASE = "emailContainUppercase",
  TEMPLATE_TAGS_REQUIRE_PREFIX = "templateTagsRequirePrefix",
  TEMPLATE_TAGS_USED_AS_METADATA = "templateTagsIsUsedAsMetadata",
  NO_VALID_ID = "noValidId",
  QUESTION_VALIDATION_ERROR = "questionValidationError",
  INVALID_PHONE_NUMBER = "invalidPhoneNumber",
}

export type TInputAttributeLang = {
  title: string;
  defaultTitle: string;
  description: string; // FIXME: check if it is in the right place
  tooltip: string;
  placeholder?: string;
  options: {
    [optionName: string]: string;
  };
  customErrorMessages: {
    [errorMessage: string]: string;
  };
  customMessage: {
    [message: string]: string;
  };
  templates: any;
  selectModal: TSelectModalLang;
  errorOnRemove: TConfirmModalLang;
  warningOnRemove: TConfirmModalLang;
  warningMessage: TConfirmModalLang;
  createEditModal: TCreateEditModalLang & {
    changeType: string;
  };
  hoverToolTips: THoverToolTipsLang;
};

export type TConfirmModalLang = {
  title?: string;
  description?: ReactNode;
};

export type TSelectModalLang = {
  title: string;
  description?: string;
};

export type TActivityScreenLang = {
  addSubquestionButton: string;
  customerSelection: string;
  assignSelection: string;
  changeTypeButton: string;
  questionType: string;
  modal: {
    newQuestion: string;
    editQuestion: string;
    viewQuestion: string;
    duplicateQuestion: string;
    typeQuestion: {
      title: string;
      searchPlaceholder: string;
    };
  };
  sidebar: {
    download: {
      inJson: string;
      inCsv: string;
    };
    createQuestionButton: string;
    uploadJSONButton: string;
    activityAlternativeText: string;
    downloadDescription: string;
  };
  errors: {
    atLeastOneSubQuestion: string;
    atLeastOneQuestion: string;
    activityWithSameName: string;
  };
  tabs: {
    questionsTab: string;
    settingsTab: string;
    previewTab: string;
  };
  empty: {
    title: string;
    description: string;
  };
  hoverToolTips: THoverToolTipsLang;
};

export type TWarningModalLang = {
  title: string;
  description: string;
};

export type TCreateEditModalLang = {
  createTitle: string;
  editTitle: string;
  notFound?: string;
  section?: { [sectionName: string]: string };
  title?: string;
  description?: string;
  save: string;
  cancel: string;
  createWarningModal?: TWarningModalLang;
} & {
  [inputAttName: string]: TInputAttributeLang;
};

export type TRunJobModalLang = {
  jobTitle: string;
  title?: string;
  description?: string;
} & {
  [inputAttName: string]: TInputAttributeLang;
};

export type TLockModalLang = {
  title: string;
  description: string;
} & {
  [inputAttName: string]: TInputAttributeLang;
};

export type TArchiveDeleteModalLang = {
  title: string;
  description: string;
  inputName: TInputAttributeLang;
  warningModal: TConfirmModalLang;
};

export enum LANG_GENERIC_TERMS {
  YES = "yes",
  NO = "no",
  NONE = "none",
  NOT_FOUND = "notFound",
  TO = "to",
  NEXT = "next",
  SAVE = "save",
  SAVE_CHANGES = "saveChanges",
  DISCARD = "discard",
  DISCARD_CHANGES = "discardChanges",
  CANCEL = "cancel",
  CREATE = "create",
  DOWNLOAD = "download",
  RELOAD = "reload",
  LOADING = "loading",
  VIEW = "view",
  HELP = "help",
  RELEASES = "releases",
  MANDATORY = "mandatory",
  ARCHIVE = "archive",
  DELETE = "delete",
  EDIT = "edit",
  LICENSED = "licensed",
  LICENSE = "license",
  LICENSES = "licenses",
  UNLICENSED = "unlicensed",
  REFRESH = "refresh",
  REPLICATE = "replicate",
  RESTORE = "restore",
  STOP = "stop",
  YES_ACTION = "yes_action",
  NO_ACTION = "no_action",
  RANGE_A_TO_B = "rangeAtoB",
  RANGE_A_TO_B_OF_C = "rangeAtoBofC",
  EDIT_DETAILS = "editDetails",
  EXPAND = "expand",
  COLLAPSE = "collapse",
  OPERATORS = "operators",
  CONTENT = "content",
  ARCHIVED = "archived",
  VALIDATE = "validate",
  PERIOD = "period",
  PERIODSHORT = "periodShort",
  PERIODS = "periods",
  PERIODSSHORT = "periodsShort",
  TERMS_AND_CONDITIONS = "termsandConditions",
  PRIVACY_POLICY = "privacyPolicy",
  LOGIN = "login",
  SIGNUP = "signup",
  COMING_SOON = "comingSoon",
  SELECT_AN_OPTION = "selectAnOption",
  COPY_IN_CLIPBOARD = "copyInClipboard",
  DEFAULT = "default",
  SAVE_X_PERCENT = "SAVE_X%",
  SELECTALL = "selectAll",
  SELECTNONE = "selectNone",
  NOTOPTIONSAVAILABLE = "noOptionsAvailable",
  UNKNOWN = "unknown",
  UNKNOWN_WITH_ID = "unknownWithId",
  All = "All",
  PER_X = "PER_X",
  CUMULATIVE = "cumulative",
  COUNT = "count",
  DENSITY = "density",
  METADATA = "metadata",
  FORM_ERROR = "formError",
  DOWNLOAD_OPTIONS = "downloadOptions",
  APPROVE = "approve",
  DECLINE = "decline",
}

export enum LANG_ACTIONS {
  ADD = "add",
  BACK = "back",
  BUY_LICENSES = "buyLicenses",
  CANCEL = "cancel",
  CONTINUE = "continue",
  CREATE = "create",
  EDIT = "edit",
  ARCHIVE = "archive",
  RESTORE = "restore",
  DOWNGRADE = "downgrade",
  DOWNLOAD = "download",
  RELOAD = "reload",
  HELP = "help",
  DELETE = "delete",
  FETCH = "fetch",
  DISPLAY = "display",
  UPLOAD = "upload",
  LICENSE = "license",
  UNLICENSE = "unlicense",
  ASSIGN = "assign",
  UNASSIGN = "unassign",
  RUN = "run",
  STOP = "stop",
  CLOSE = "close",
  REPLICATE = "replicate",
  RESUME = "resume",
  DUPLICATE = "duplicate",
  RESET_PASSWORD = "resetPassword",
  DOWNLOAD_EXCEL = "downloadExcel",
  DOWNLOAD_ZIP = "downloadZip",
  DOWNLOAD_CSV = "downloadCSV",
  UPGRADE = "upgrade",
  WRITE_TO_US = "writeToUs",
  LOAD_MORE = "loadMore",
  SEND = "send",
  CHANGE_TAG = "changeTag",
}

export enum LANG_COMPONENTS {
  TABLE = "table",
  SEARCH = "search",
  ERROR = "errors",
  EXPANSION_PANEL = "expansionPanel",
  FILTERS = "filters",
  META_EXPRESSIONS = "metaExpressions",
  INPUT_ERRORS = "inputErrors",
  UPDATE_MONITOR = "updateMonitor",
  INPUT_CONDITION = "inputCondition",
  INPUT_OPERATION = "inputOperation",
  MAPS = "maps",
  CARD = "card",
  COLUMNS = "columns",
  DIALOG = "dialog",
  EMPTY_TABLE = "emptyTable",
  INPUT_SELECT = "inputSelect",
  INPUT_GPS_BUTTON = "inputGPSButton",
  INPUT_MULTIPLE_CREATE = "inputMultipleCreate",
  INPUT_IMAGE = "inputImage",
  INPUT_METAEXPRESSION = "inputMetaExpression",
  INPUT_MULTIPLE_SELECT = "inputMultipleSelect",
  INPUT_MATRIX_EDITOR = "inputMatrixEditor",
  ITEM_GALLERY = "itemGallery",
  INPUT_VISIBLE_BY_PLAN = "inputVisibleByPlan",
}

export enum LANG_ACTION_NOTIFICATIONS {
  CREATE = "create",
  EDIT = "edit",
  DELETE = "delete",
  ARCHIVE = "archive",
  RESTORE = "restore",
  DOWNLOAD = "download",
  RELOAD = "reload",
  HELP = "help",
  FETCH = "fetch",
  LICENSE = "license",
  UNLICENSE = "unlicense",
  UPLOAD = "upload",
  OTHER_ACTION = "otherAction",
  RESETTING_PASSWORD = "resettingPassword",
}

export enum LANG_OTHER_UI_ACTIONS {
  DOWNLOAD_RAW = "downloadRaw",
  DOWNLOAD_PDF = "downloadPDF",
  SINGLE_GENERIC_ACTION = "singleGenericAction",
  BULK_GENERIC_ACTION = "bulkGenericAction",
  BULK_GENERIC_ACTION_FROM_CSV = "bulkGenericActionFromCSV",
  RUN_IN_BACKGROUND_MESSAGE = "runInBackgroundMessage",
  HIDE_ARCHIVE = "hideArchive",
  DISPLAY_ARCHIVE = "displayArchive",
}

export type TOperator = CONDITION_OPERATOR | OPERATION_OPERATOR;

type TlangBaseGenericTerms = {
  [key in LANG_GENERIC_TERMS]: string;
};

export type TLangGenericOperators = {
  operators: {
    [key in TOperator]: string;
  };
};

type TLangGenericContent = {
  content: {
    nothing: string;
    templateField: string;
  };
};

type TPeriod = {
  day: string;
  hour: string;
  min: string;
};
type TTime = {
  period: TPeriod;
  periodShort: TPeriod;
  periods: TPeriod;
  periodsShort: TPeriod;
};

type TLangGenericTerms = TlangBaseGenericTerms &
  TLangGenericOperators &
  TLangGenericContent &
  TTime;

type TLangPeriods = {
  day: string;
  week: string;
  month: string;
  year: string;
  days: string;
  weeks: string;
  months: string;
  years: string;
  daily: string;
  weekly: string;
  monthly: string;
  yearly: string;
};

type TLangActions = {
  [key in LANG_ACTIONS]: string;
};

type TLangComponents = {
  inputImage: {
    remove: string;
    add: string;
    addURL: string;
    noImageSelected: string;
    fileTypeNotSupported: string;
  };
  inputErrors: { [key in ERROR_LIST]: string };
  filters: any;
  columns: any;
  metaExpressions: {
    tooltip: string;
    scope: { [key in META_EXPRESSION_SCOPE]: string };
  };
  inputSelect: {
    noOptions: string;
    scope: {
      [key in LIST_SCOPE]: string;
    };
    boolean: {
      true: string;
      false: string;
    };
    clear: string;
    folderType: { [key in FOLDER_TYPE]: string };
  };
  inputGPSButton: TInputAttributeLang;
  operators: any;
  maps: any;
  search: string;
  table: any;
  expansionPanel: any;
  inputMetaExpression: TInputAttributeLang;
  updateMonitor: TInputAttributeLang;
  inputCondition: TInputAttributeLang;
  inputOperation: TInputAttributeLang;
  inputMultipleCreate: {
    options: {
      addNew: string;
    };
  };
  inputMultipleSelect: {
    limitWarningModal: TWarningModalLang;
  };
  card: any;
  errors: any;
  emptyTable: {
    title: string;
    itemTitle: string;
    users: {
      description: string;
    };
    teams: {
      description: string;
    };
    lists: {
      description: string;
      items: {
        description: string;
        title: string;
      };
    };
    documents: {
      description: string;
    };
    activities: {
      description: string;
    };
    dashboards: {
      description: string;
    };
    searchFilter: {
      title: string;
      search: {
        description: string;
      };
      filter: {
        clearButton: any;
        description: string;
      };
    };
  };
  inputMatrixEditor: TInputAttributeLang;
  itemGallery: TInputAttributeLang;
  inputVisibleByPlan: TCreateEditModalLang;
  dialog: {
    bulkAddOptionsFromCSV: any;
  };
};

type TLangMainLayout = {
  selectedClient: string;
  selectedLang: string;
  defaultLang: string;
  frenchLanguage: string;
  englishLanguage: string;
  espagnolLanguage: string;
  navbar: {
    menu: {
      organisation: string;
      switchClient: string;
      changeClient: string;
      modal: {
        searchClient: string;
        changeClientButton: string;
      };
    };
  };
  sidebar: {
    summary: string;
    home: string;
    users: string;
    mobileUsers: string;
    webUsers: string;
    dashboards: string;
    clients: string;
    list: string;
    lists: string;
    customers: string;
    map: string;
    pictures: string;
    documents: string;
    workflows: string;
    workflowBuilder: string;
    activities: string;
    jobs: string;
    triggers: string;
    orders: string;
    webhooks: string;
    teams: string;
    database: string;
    environment: string;
  };
  // buttons
  logOutButton: string;
  forceSyncButton: string;
  documentationButton: string;
  giveFeedbackButton: string;
  goToFPDownloadButton: string;
  lastUpdate: string;
  goToHelpCenterButton: string;
  goToContactSupportButton: string;
  goToViewSupportRequestsButton: string;
  editProfileButton: string;
  whatsNewAtFieldProButton: string;
  changePasswordButton: string;
  goToStripePortalButton: string;
  // modals
  editProfileModal: any;
  changePasswordModal: any;
  banner: {
    title: string;
  };
};

type TLangInProgressNotifications = {
  [key in LANG_ACTION_NOTIFICATIONS]: string;
};

type TLangSuccessNotifications = {
  default: string;
  defaultPlural: string;
  downloadList: string;
  downloadChart: string;
  copyInClipboardSuccess: string;
};

type TLangErrorNotifications = {
  default: string;
  internalError: string;
  validationError: string;
  networkError: string;
  freeTrialReachedError: string;
  copyInClipboardError: string;
};

type TLangNotifications = {
  inProgressNotifications: TLangInProgressNotifications;
  successNotifications: TLangSuccessNotifications;
  errorNotifications: TLangErrorNotifications;
};

type TLangModal = {
  confirm: string;
  cancel: string;
  save: string;
  add: string;
  test: string;
  create: string;
  delete: string;
  archive: string;
  edit: string;
  restore: string;
  proceed: string;
  upload: string;
  apply: string;
  select: string;
  recover: string;
  selectAll: string;
  deselectAll: string;
  validationText: string;
  dataLossMessage: string;
  confirmationMessage: string;
  downloadWIP: string;
  downloadCSV: {
    title: string;
    tooltip: string;
  };
  uploadWIP: string;
  loadWIPModalTitle: string;
  loadWIPModalDescription: string;
  warningTitle: string;
  createEditOption: {
    createTitle: string;
    editTitle: string;
    inputKey: TInputAttributeLang;
    inputLabel: TInputAttributeLang;
    inputIgnorePropagation: TInputAttributeLang;
  };
  confirmCloseModal: {
    title: string;
    description: string;
  };
  lockModal: TLockModalLang;
  uploadCSVMessage: {
    message: string;
    errorMessage: string;
  };
  archiveDeleteMultiple: {
    title: string;
    description: string;
  };
  archiveDelete: {
    title: string;
    description: string;
  };
  archiveMessage: string;
  deleteMessage: string;
  bulk: {
    warningOnConfirm: {
      description: string;
      title: string;
    };
    mainModal: {
      title: string;
      description: string;
    };
    importErrors: {
      [key in string]: string;
    };
  };
  downloadConfirm: string;
  tabs: {
    settings: string;
    overview: string;
    conditions: string;
    all: string;
    content: string;
    recipients: string;
    parameters: string;
    step_settings: string;
  };
  changeTag: {
    title: string;
    [inputName: string]: string;
  };
};

type TLangLoginScreen = {
  passwordLostModal: TCreateEditModalLang;
  passwordLostMessageModal: TCreateEditModalLang;
  passwordLostButton: string;
  inputEmail: TInputAttributeLang;
  inputPassword: TInputAttributeLang;
  googleLoginButton: string;
  emailPasswordLoginButton: string;
  orSeparator: string;
  titleLogin: string;
  subTitleLogin1: string;
  subTitleLogin2: string;
  error: {
    wrongInformationProvided: string;
  };
} & {
  [inputAttName: string]: TInputAttributeLang;
};

type TLangDefaultScreen = {
  subject: string;
  subjects: string;
  chooseClient: string;
  actions: TCustomDirectActionLabel;
  columns: any;
  createEditModal?: TCreateEditModalLang;
  createMenu: any;
  editMenu: any;
  archiveModal?: TArchiveDeleteModalLang & { warningText: string };
  deleteModal?: TArchiveDeleteModalLang;
  replicateModal?: TCreateEditModalLang & { replicateTitle: string };
  customNotifications: any;
};

type TPLANG_ID = PLAN_ID | "entreprise";

type TLangPlans = {
  [key in TPLANG_ID]: {
    name: string;
    description: string;
    priceLabel: string;
    currentPlan: string;
    subscribeButton: string;
    featuresTitle: string;
    features: {
      [key: string]: string;
    };
  };
};

type TLangPacks = {
  [key in PACK_ID]: {
    features: {
      [key: string]: string;
    };
  };
};

export type TRawExports = {
  activity: {
    mainSheet: string;
    matrixSheet: string;
    schemaSheet: string;
  };
  list: {
    itemSheet: string;
    schemaSheet: string;
  };
  pdf: {
    [key: string]: string;
  };
};

export type TLang = {
  genericTerms: TLangGenericTerms;
  periods: TLangPeriods;
  rawExports: TRawExports;
  actions: {
    [key in LANG_OTHER_UI_ACTIONS]: string;
  } & TLangActions;
  components: TLangComponents;
  mainLayout: TLangMainLayout;
  notifications: TLangNotifications;
  modal: TLangModal;
  containers: {
    calendar: {
      subCategories: {
        calendar: TLangDefaultScreen & {
          createEditModal: TCreateEditModalLang;
          events: TLangDefaultScreen;
        };
        calendarEvent: TLangDefaultScreen;
      };
    };
    triggers: {
      subCategories: {
        triggers: TLangDefaultScreen & {
          createEditModal: TCreateEditModalLang;
        };
      };
    };

    login: {
      subCategories: {
        login: TLangLoginScreen;
      };
    };
    clients: {
      subCategories: {
        clients: TLangDefaultScreen & {
          createEditModal: TCreateEditModalLang;
          deleteModal: TArchiveDeleteModalLang;
          setClientLiveModal: TConfirmModalLang;
          clientForm: TClientFormLang;
        };
        folders: TLangDefaultScreen & {
          createEditModal: TCreateEditModalLang;
          deleteModal: TArchiveDeleteModalLang;
        };
      };
    };
    users: {
      subCategories: {
        mobileUsers: TLangDefaultScreen & {
          deleteModal: TArchiveDeleteModalLang;
          userAlreadyInTeamModal: any;
          maxLicencesModal: any;
          createEditModal: TCreateEditModalLang;
          createMenu: any;
        };
        webUsers: TLangDefaultScreen & {
          deleteModal: TArchiveDeleteModalLang;
          createEditModal: TCreateEditModalLang;
          bulkCreateModal: TCreateEditModalLang;
          createMenu: any;
        };
      };
      deleteModal: TArchiveDeleteModalLang;
    };
    orders: {
      subCategories: {
        orders: TLangDefaultScreen & {
          createEditModal: TCreateEditModalLang;
        };
      };
    };
    teams: {
      subCategories: {
        teams: TLangDefaultScreen & {
          createEditModal: TCreateEditModalLang;
          bulkCreateModal: TCreateEditModalLang;
          manageHierarchyModal: TCreateEditModalLang;
          createMenu: any;
        };
        groups: TLangDefaultScreen & {
          deleteGroupModal: TArchiveDeleteModalLang;
          archiveGroupModal: TArchiveDeleteModalLang;
          deleteGroupsModal: TArchiveDeleteModalLang;
          archiveGroupsModal: TArchiveDeleteModalLang;
        };
      };
    };
    lists: {
      subCategories: {
        lists: TLangDefaultScreen & {
          createEditModal: TCreateEditModalLang;
          archiveModal: TArchiveDeleteModalLang;
          warningModal: any;
          bulkAddOptionsFromCSV: any;
        };
        items: TLangDefaultScreen & {
          createEditModal: TCreateEditModalLang & {
            createTitleShort: string;
          };
          deleteModal: TArchiveDeleteModalLang;
          bulkEditModal: any;
          bulkDownload: {
            column: [name: string];
          };
          createEditFindDuplicateModal: TCreateEditModalLang;
          detailDialog: {
            emptyPictures: string;
            emptyNotes: string;
            insights: string;
            qrCode: string;
            notes: string;
            emptyActivityReportsPictures: string;
            activityReportsPicturesTitle: string;
            loadingActivityReportsPictures: string;
          };
        };
        territories: TLangDefaultScreen & {
          displayTerritories: string;
          displayCustomers: string;
          satelliteView: string;
          totalGeoTaggedCustomersCount: string;
          totalTerritoriesCount: string;
          bulkCreateModal: TCreateEditModalLang & {
            actionsDescription: {
              single: string;
              plural: string;
            };
          };
          createTerritoryModal: TCreateEditModalLang;
          deleteTerritoriesModal: TArchiveDeleteModalLang;
          deleteTerritoryModal: TArchiveDeleteModalLang;
          editTerritoryModal: TCreateEditModalLang;
          inputCreateShape: {
            title: string;
            menuOptions: {
              draw: string;
            };
            drawMode: {
              description: string;
            };
          };
          inputEditShape: {
            editMode: {
              title: string;
              editButton: string;
              resetButton: string;
            };
            drawMode: {
              title: string;
              description: string;
            };
          };
        };
        map: TLangDefaultScreen;
      };
    };
    dashboards: {
      subCategories: {
        dashboards: TLangDefaultScreen & {
          createEditModal: TCreateEditModalLang;
          defaultDashboards: {
            [key in DEFAULT_DASHBOARD]: string;
          } & {
            [key: string]: any;
          };
          statusType: {
            [key in DASHBOARD_STATUS_TYPE]: string;
          };
          timeoutChart: {
            title: string;
            downloadable: string;
            notDownloadable: string;
            downloadMessage: { title: string; description: string };
          };
        };
      };
    };
    environment: {
      subCategories: {
        tableOptimizations: TLangDefaultScreen & {
          createEditModal: TCreateEditModalLang;
        };
        databaseSchema: TLangDefaultScreen & {
          detailTableView: string;
          simpleTableView: string;
          direction: string;
        };
        sections: {
          [key: string]: { title: string; description: string };
        };
      };
    };
    workflows: {
      subCategories: {
        workflows: TLangDefaultScreen & {
          createEditModal: TCreateEditModalLang & {
            searchTitle: string;
          };
          createEditTagModal: TCreateEditModalLang;
          steps: {
            subjects: string;
            subject: string;
          };
          stepConfigurationDialog: TCreateEditModalLang /* {
            scope: string;
            parameters: string;
            condition: string;
            assignmentRules: string;
            delete: string;
            save: string;
          };*/;
          active: string;
          archived: string;
        };
        webhooks: TLangDefaultScreen & {
          createEditModal: TCreateEditModalLang;
        };
        activityReports: TLangDefaultScreen & {
          detailDialog: {
            details: string;
            pictures: string;
            emptyPictures: string;
            empty: string;
            editDisabledTooltip: string;
            disabledIconTooltip: {
              [key: string]: string;
            };
          };
          sharePdfButton: {
            loadingMessage: string;
            tooltip: string;
            modal: {
              description: string;
            };
          };
          assignToMe: {
            title: string;
            buttonTitle: string;
            columns: {
              [item: string]: string;
            };
          };
        };

        workflowReports: TLangDefaultScreen;
        jobHistory: TLangDefaultScreen;
        activities: TLangDefaultScreen & {
          createEditModal: TCreateEditModalLang;
          createEditTagModal: TCreateEditModalLang;
          active: string;
          archived: string;
          questions: TActivityScreenLang;
        };
        metaExpressions: TLangDefaultScreen & {
          createEditModal: TCreateEditModalLang;
        };
      };
    };

    jobs: {
      subCategories: {
        jobs: TLangDefaultScreen & {
          createEditModal: TCreateEditModalLang;
        };
        transformations: TLangDefaultScreen & {
          createEditModal: TCreateEditModalLang;
          runJobModal: TRunJobModalLang;
          initJobModal: TCreateEditModalLang;
        };
        notifications: TLangDefaultScreen & {
          createEditModal: TCreateEditModalLang;
          runJobModal: TRunJobModalLang;
        };
        http_requests: TLangDefaultScreen & {
          createEditModal: TCreateEditModalLang;
          runJobModal: TRunJobModalLang;
        };
        integrations: TLangDefaultScreen & {
          createEditModal: TCreateEditModalLang;
        };
        timer: TLangDefaultScreen & {
          createEditModal: TCreateEditModalLang;
          runJobModal: TRunJobModalLang;
        };
        streams: TLangDefaultScreen & {
          createEditModal: TCreateEditModalLang;
          runJobModal: TRunJobModalLang;
        };
        scripts: TLangDefaultScreen & {
          createEditModal: TCreateEditModalLang;
          runJobModal: TRunJobModalLang;
        };
        prediction: TLangDefaultScreen & {
          createEditModal: TCreateEditModalLang;
        };
        updateQueryAttributes: TLangDefaultScreen & {
          createEditModal: TCreateEditModalLang;
        };
      };
    };
    documents: {
      subCategories: {
        documents: TLangDefaultScreen & {
          createEditModal: TCreateEditModalLang;
          deleteModal: TArchiveDeleteModalLang;
          scope: {
            [key in DOCUMENT_SCOPE]: string;
          };
        };
      };
    };
    pictures: {
      subCategories: {
        pictures: TLangDefaultScreen & {
          createEditModal: TCreateEditModalLang;
          galleryModal: any;
          downloadModal: any;
        };
      };
    };

    home: {
      title: {
        translation: string;
      };
      overview: {
        translation: string;
      };
      subtitle: {
        translation: string;
      };
      frequentlyViewedDashboards: {
        title: {
          translation: string;
        };
      };
      homeDashboard: {
        title: {
          translation: string;
        };
      };
      containers: {
        home: TCreateEditModalLang;
      };
    };
  };
  plans: TLangPlans;
  packs: TLangPacks;
};

type TClientFormLang = {
  automatedBillingTab: {
    planOverview: {
      title: string;
      description: string;
      total: string;
      subTotal: string;
      inputLicensePlan: {
        title: string;
        noPlan: string;
        subscribe: string;
        change: string;
        perLicensePer: string;
      };
      inputNumberOfLicenses: {
        title: string;
        button: string;
        licence: string;
        licences: string;
      };
      inputNumberOfLicensesModal: {
        title: string;
        confirm: string;
        youCurrentlyHave: string;
        inputLabel: string;
        inputSubText: string;
      };
      projectedTotal: {
        title: string;
      };
      inputBillingCycle: {
        title: string;
        switchTo: string;
        switchToMonthly: string;
        switchToYearly: string;
        perLicensePer: string;
      };
      inputBillingCycleModal: {
        title: string;
        inputTitle: string;
        saveXPercent: string;
        perLicensePer: string;
        confirm: string;
      };
      inputSupportPack: {
        title: string;
        add: string;
        change: string;
      };
      inputSupportPackModal: {
        title: string;
        details: string;
        inputSubText: string;
        add: string;
      };
      cancelSubscriptionModal: TConfirmModalLang;
      restartSubscriptionModal: TConfirmModalLang;
    };
  };
  clientStatusBanner: {
    subscriptionCancelDate: string;
    unableToProcessPayment: string;
  };
};

export default TLang;
