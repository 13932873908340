import * as Sentry from "@sentry/react";

const initSentry = () => {
  if (!process.env.REACT_APP_SENTRY_DSN) {
    // eslint-disable-next-line no-console
    console.error("Sentry could not be initialised");
    return;
  }

  const environment = getSentryEnvironment();
  const isProductionEnv = process.env.NODE_ENV === "production";

  Sentry.init({
    environment,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Sentry.Replay(),
      new Sentry.BrowserProfilingIntegration(),
      new Sentry.BrowserTracing({
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],

    /* Session replay */

    // -> this seems to be causing performance issues on our forms (which are not well optimized)
    replaysSessionSampleRate: 0, // isProductionEnv ? 0.1 : 0.25,
    // // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    replaysOnErrorSampleRate: 0, // 1.0,

    /* Performance Monitoring */

    tracesSampleRate: isProductionEnv ? 0.1 : 0.25,
    // Controls for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/web.v3(.develop|.staging|).fieldproapp.com/,
    ],
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // result in 25% of transactions being profiled (0.5 * 0.5 = 0.25)
    profilesSampleRate: isProductionEnv ? 0.1 : 0.25,

    /* Errors */

    ignoreErrors: [
      /**
       * Thrown when firefox prevents an add-on from refrencing a DOM element that has been removed.
       * This can also be filtered by enabling the browser extension inbound filter
       */
      "TypeError: can't access dead object",
      /**
       * React internal error thrown when something outside react modifies the DOM
       * This is usually because of a browser extension or Chrome's built-in translate
       */
      "NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
      "NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.",
      "NotAllowedError: Not allowed error: action is not authorised for this user",
      "Network Error",
      "ResizeObserver loop limit exceeded",
      "ResizeObserver loop completed with undelivered notifications",
      "timeout of",
      "out of memory",
      "Request aborted",
    ],
  });
};

/**
 *
 * @returns local if front-end is local, REACT_APP_ENV value otherwise
 * NOTE: we could try to always return REACT_APP_ENV and see if it helps Sentry unify FE/BE issues
 * We would still be able to distinguish FE localhost issues by looking at the page URL
 */
const getSentryEnvironment = () => {
  const env = process.env.NODE_ENV; // represents the "build" env
  const reactEnv = process.env.REACT_APP_ENV; // represents which backend we are using

  // We could be connecting to a develop, staging or prod back-end,
  // but we want development errors to appear in the "local" environment in Sentry
  // (The front-end is what matters here, and in this case it's localhost)
  if (env === "development") {
    return "local";
  }

  // A staging or production build should generate errors in the matching Sentry environment
  return reactEnv;
};

export default initSentry;
